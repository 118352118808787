export default {
  "AddAddress": {
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres kayıt edildi."])},
    "adding-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres ekleme"])},
    "adding-personal-company-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesilecek fatura türüne göre şahış ve şirket adresi ekleyebilirsiniz."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için gerekli yetkiniz bulunmamaktadır."])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres kayıt edildi."])}
  },
  "EditWrapper": {
    "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasarım"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokümanlar"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gözden geçirme"])},
    "signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılar"])}
  },
  "acceptUpgradePersonalPlan": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "plan-upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan yükseltme"])},
    "upgrade-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı yükselt"])}
  },
  "actions": {
    "connection-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunuculara erişilemedi."])},
    "server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucuda hata oluştu."])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim. Giriş sayfasına yönlendirileceksiniz."])},
    "unknown-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilinmeyen fonksiyon."])}
  },
  "addressCompany": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şehir"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Adı"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
    "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki bilgileri eksik. Lütfen eksik bilgileri doldurun. "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "tax-administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi Dairesi"])},
    "tax-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi No"])}
  },
  "addressPersonal": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şehir"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
    "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki bilgileri eksik. Lütfen eksik bilgileri doldurun. "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "tr-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TC No"])}
  },
  "apiUserAddEdit": {
    "account-a-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba bir isim verin"])},
    "allowed-ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
    "api-user-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Anahtarı Güncelleme"])},
    "api-users-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP hesapları yönetimi"])},
    "app-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Şifresi"])},
    "application-name-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama adı girin"])},
    "applicaton-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Adı"])},
    "authorized-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkili Kodu"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "citizienship-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T.C. kimlik numarasını girin"])},
    "delete-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "enter-account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap adı girin"])},
    "enter-kep-mail-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP posta adresini girin"])},
    "enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre girin"])},
    "enter-password-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola girin"])},
    "enter-the-authorized-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkili kodu girin"])},
    "enter-the-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre girin"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "is-it-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Mi"])},
    "kep-postal-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Posta Adresi"])},
    "kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Sağlayıcısı"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "new-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni API Anahtarı Tanımlama"])},
    "new-api-user-by-filling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri doldurarak yeni API anahtarı tanımlayabilirsiniz."])},
    "owner-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Sahibi"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
    "password-sifre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "select-kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP sağlayıcısı seçiniz..."])},
    "tc-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TC Kimlik No"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "update-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Hesabı Güncelleme"])},
    "update-kep-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri değiştirerek API anahtarı bilgilerini güncelleyebilirsiniz."])},
    "user-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API türü"])}
  },
  "apiUserManagement": {
    "account-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt silindi."])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akitf"])},
    "add-new-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
    "all-api-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm API anahtarlarını buradan yönetebilirsiniz."])},
    "allowed-ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
    "api-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Anahtarı"])},
    "api-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Anahtarları"])},
    "api-users-unauthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API anahtarlarını görmek için gerekli yetkiniz bulunmuyor."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "created0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API anahtarı oluşturuldu."])},
    "created1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen aşağıdaki anahtarı güvenli bir yere kayıt ediniz."])},
    "created2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anahtarı tekrar görmeniz mümkün olmayacaktır."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "delete-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API anahtarı silme"])},
    "do-you-want-to-permanently-delete-the-selected-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili API anahtarını kalıcı olarak silmek istiyor musunuz?"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "identification-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlama yapıldı"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "new-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak yeni API anahtarı tanımlayabilirsiniz"])},
    "no-api-user-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç API anahtarı tanımlanmamış"])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "system-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Adı"])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
    "update-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme yapıldı\n"])},
    "you-are-not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için yetkiniz yok."])},
    "you-can-define-a-new-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API anahtarı tanımlayabilmek için gerekli lisansınız bulunmuyor."])},
    "api-user-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])}
  },
  "authWrapper": {
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenilikler"])}
  },
  "authWrapperComponent": {
    "bank-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banka talimatları"])},
    "click-the-update-button-to-install-the-new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni versiyonu yüklemek için güncelle butonuna basınız."])},
    "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşmeler"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ücretsiz"])},
    "get-e-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["olarak  e-imzalansın"])},
    "human-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsan Kaynakları"])},
    "new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Sürüm Bulundu"])},
    "new-version-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu uyarıyı sürekli alıyorsanız tarayıcınızı kapatıp açabilirsiniz."])},
    "official-correspondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resmi Yazışmalar"])},
    "sign-up-in-one-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek adımda kayıt olun"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "your-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeleriniz"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
  "buyLicense": {
    "display-name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " adet kullanıcı hesabı"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KDV"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
  "cancelFlow": {
    "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
    "The-process-has-been-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç iptal edildi."])},
    "cancel-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci iptal Et"])},
    "close-panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paneli kapat"])},
    "give-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])},
    "process-cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç İptal Etme"])},
    "the-process-will-be-cancelled-if-payment-has-been-made-for-the-process-the-amount-paid-is-not-refundable-this-action-cannot-be-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç iptal edilecektir. Süreç için ödeme yapılmış ise ödenen miktar iade edilmez. Bu işlem geri alınamaz."])},
    "the-process-will-be-deleted-along-with-all-its-documents-and-other-data-this-action-cannot-be-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç, tüm belge ve diğer verisiyle beraber silinecektir. Bu işlem geri alınamaz."])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
  "companyInfo": {
    "mersis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MERSİS No"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket adı"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinize ait bilgiler."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Bilgisi"])}
  },
  "contactAddEdit": {
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket adı"])},
    "continue-to-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme İşlemine Devam Et!"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak oluşturma"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta"])},
    "everyone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizasyonumdaki herkes"])},
    "institution-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurum adı"])},
    "kkk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETSİS No (Kurum kimlik kodu)"])},
    "mersis-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MERSİS No"])},
    "only-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece ben"])},
    "select-a-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim seçiniz."])},
    "select-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontağın ait olacağı bir birim seçiniz."])},
    "selected-departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili birimdekiler"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri değiştirerek tanımlanmış kontağı güncelleyebilirsiniz."])},
    "sub-title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri doldurarak yeni bir kayıt  tanımlayabilirsiniz."])},
    "tax-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi no"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Güncelleme"])},
    "title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kontak Tanımlama"])},
    "what-are-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaklar nedir"])}
  },
  "contactInfo": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İl"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlçe"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta adresi"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faks"])},
    "kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP adresi"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim bilgileri."])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "tel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon 2"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim Bilgisi"])},
    "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web sitesi"])}
  },
  "contactManagement": {
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlama yapıldı"])},
    "create-new-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kontak oluştur"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt silindi"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "edit (2)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "everyone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkes"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÜR"])},
    "me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç kontak bulunmuyor"])},
    "no-record-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak yeni kontak tanımlayabilirsiniz"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAHİP"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görmeye/güncellemeye yetkili olduğunuz kayıtlar."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaklar"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme yapıldı"])}
  },
  "contactSelector": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ŞİRKET"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KURUM"])},
    "no-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama sonucu kayıt bulunamadı."])},
    "no-records-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt bulunamadı. Geçerli bir KEP adresi yazarak ekleme yapabilirsiniz"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ŞAHIS"])},
    "press-ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazılı adresi eklemek için ENTER'a basın."])},
    "the-same-record-exists-in-the-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aynı kayıt listede mevcut."])},
    "write-to-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramak için yazınız..."])}
  },
  "contactSelector.detsis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETSİS"])},
  "createPassword": {
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel verileriniz, Aydınlatma Metni kapsamında işlenmektedir. 'Hesap oluştur' butonuna basarak Kullanım Sözleşmesi’ni, KVKK Aydınlatma Metni’ni, Çerez Politikası’nı okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz."])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çerez politikası"])},
    "create-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi oluşturun"])},
    "enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre belirleyiniz..."])},
    "eula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım sözleşmesi"])},
    "eula-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Sözleşmesi, KVKK Aydınlatma Metni ve Çerez Politikası’nı kabul ettiğinizi onaylamanız gerekmektedir."])},
    "kvkk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KVKK aydınlatma metni"])},
    "login-with-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hesabınızla giriş yapın"])},
    "or": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["veya ", _interpolate(_list(0))])},
    "password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm şifre kuralları sağlanmadı. Lütfen şifre kurallarına uygun bir şifre belirleyiniz."])},
    "reenter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre tekrar"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüle"])},
    "your-personal-data-is-processed-within-the-scope-of-the-clarification-text-by-clicking-the-create-an-account-button-you-confirm-that-you-have-read-and-accepted-the-terms-of-use-kvkk-clarification-text-cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "createSignatureImage": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])},
    "current-signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut imza"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen imzanızı çiziniz."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzanızı beyaz alana çizmek için imleci kullanın veya bilgisayarınızdan bir resim yükleyin."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzanız kayıt edildi."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzanızı çizin"])}
  },
  "creatorInfo": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan e-Yazışma paketlerinin gönderen bilgisini düzenleyebilirsiniz."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma Gönderen Bilgisi"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgiler güncellendi."])}
  },
  "creditCard": {
    "16-digit-card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16 haneli kart numarası"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "billing-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatura Bilgileri"])},
    "billing-information-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen fatura bilgisi seçiniz."])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Numarası"])},
    "ccv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCV"])},
    "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal"])},
    "goodsservices-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan Mal/Hizmet"])},
    "make-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yap"])},
    "moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay"])},
    "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Soyad"])},
    "operation-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için yetkiniz bulunmamaktadır."])},
    "payment-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme hatası"])},
    "payment-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Tutarı"])},
    "paytr-payment-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYTR ödeme sonucu dönmedi."])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeniz PayTR üzerinden gerçekleşecektir. ONAYLARIM hiçbir şekilde kredi kartı bilginizi almaz veya saklamaz."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi Kartı ile Ödeme"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıl"])}
  },
  "creditCardPaymentHistory": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TUTAR"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TARİH"])},
    "e-mail-sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Address")), " adresine fatura gönderim işlemi alınmıştır."])},
    "email-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturayı e-Postala"])},
    "no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı ile yapılmış ödemeniz bulunmuyor"])},
    "no-receipt-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ödeme için fatura henüz oluşturulmamış. En kısa zamanda e-fatura oluşturularak size e-posta atılacaktır."])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "no-right-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı işlemlerini görmek için gerekli yetkiniz bulunmuyor"])},
    "no-right-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için gerekli yetkiniz bulunmamaktadır."])},
    "plan-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu planda kredi kartı işlemleri bulunmamaktadır"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı ile yapılan tüm ödemelerinizi burada bulabilirsiniz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeler"])},
    "view-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci Görüntüle"])}
  },
  "creditHistory": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TUTAR"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAKİYE"])},
    "buy-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak kredi satın alabilirsiniz"])},
    "cannot-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu üyelik türünde kredi satın alınamaz."])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KREDİ"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TARİH"])},
    "e-mail-sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Address")), " adresine fatura gönderim işlemi alınmıştır."])},
    "get-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi al"])},
    "no-receipt-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ödeme için fatura henüz oluşturulmamış. En kısa zamanda e-fatura oluşturularak size e-posta atılacaktır."])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi hareketiniz bulunmuyor"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için gerekli yetkiniz bulunmamaktadır."])},
    "no-right-to-buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi alma yetkiniz bulunmuyor."])},
    "no-rigth-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi hareketlerini görmek için gerekli yetkiniz bulunmuyor"])},
    "plan-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu planda kredi hareketleri bulunmamaktadır"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç"])},
    "send-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturayı e-Postala"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kredi hareketlerinizi burada bulabilirsiniz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi hareketleri"])},
    "total-credit-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Kredi Miktarı"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci Görüntüle"])}
  },
  "currentLicense": {
    "add-license-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut planda kullanıcı lisansı satın alınamaz. Kurumsal planına geçiş yapabilirsiniz"])},
    "additional-users-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel hesaplarda ek kullanıcı bulunamaz."])},
    "available-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılabilir Kullanıcı Hesabı Sayısı"])},
    "buy-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisans satın al"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Adı"])},
    "company-trade-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Ticari Unvanı"])},
    "current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Plan"])},
    "error-fetching-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlar getirilirken hata oluştu"])},
    "get-additional-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek Kullanıcı Al"])},
    "license-end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisans Bitiş Tarihi"])},
    "license-start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisans Başlangıç Tarihi"])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim."])},
    "no-right-error-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisans durumunu görmek için gerekli yetkiniz bulunmuyor"])},
    "number-of-free-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş Kullanıcı Sayısı"])},
    "on-premise-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Premise kullanım"])},
    "on-premise-error-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Premise kullanımlarda bu özellik kullanılmaz"])},
    "personal-account-company-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel hesaplarda şirket adı belirlenemez."])},
    "personal-account-company-official-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel hesaplarda şirket ticari unvanı belirlenemez."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncel plan ve kullanıcı durumu."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisans Durumu"])},
    "total-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Kullanıcı Hesabı"])},
    "total-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Kullanıcı Sayısı"])},
    "upgrade-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan Yükselt"])},
    "used-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılan Kullanıcı Hesabı"])},
    "pades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAdES İmza Modülü"])},
    "cades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAdES İmza Modülü"])},
    "e-yazisma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma Modülü"])},
    "kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Modülü"])},
    "sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Modülü"])},
    "externalusers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dış Kullanıcıya İmzaya Gönderme"])},
    "advanced-flows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş Süreç Modülü"])},
    "api-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SignHub API Anahtarı Sayısı"])},
    "core-api-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primeAPI Anahtarı Sayısı"])}
  },
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
  "deleteAccount": {
    "all-your-data-will-be-deleted-along-with-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızla beraber tüm veriniz silinecektir."])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkat!"])},
    "delete-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız silindi."])},
    "delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız silinemedi. Konuyla ilgili teknik ekibimiz inceleme başlatmıştır."])},
    "delete-my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabımı sil"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız ve sistemdeki tüm veriniz silinecektir. Bu işlem geri alınamaz. Silme işlemine devam etmek için şifrenizi yazıp hesabı sil butonuna basınız."])},
    "this-action-cannot-be-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Silme"])},
    "your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreniz"])}
  },
  "deleteFilesOfFlow": {
    "Documents-in-the-process-were-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç içindeki belgeler silindi."])},
    "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
    "all-documents-in-the-process-will-be-deleted-this-action-cannot-be-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürecin içerisindeki tüm belgeler silinecektir. Bu işlem geri alınamaz."])},
    "delete-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeleri sil"])},
    "deleting-process-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Dosyaları Silme"])}
  },
  "deleteFlow": {
    "actionundone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "process-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Silme"])},
    "processdelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç silindi"])},
    "processdeletionother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç, tüm belge ve diğer verisiyle beraber silinecektir."])}
  },
  "departmentRoleAddEdit": {
    "are-subunits-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Birimler Dahil Mi"])},
    "is-it-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Mi"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "select-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı seçiniz."])},
    "sub-title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri girerek birim yetkisi tanımlayabilirsiniz."])},
    "sub-title-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri değiştirerek birim yetkisini güncelleyebilirsiniz."])},
    "title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Birim Yetkisi Tanımlama"])},
    "title-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim Yetkisi Güncelleme"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
    "what-are-unit-authorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim yetkileri nedir"])}
  },
  "departmentsTree": {
    "add-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanına birim ekle"])},
    "add-under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altına birim ekle"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim eklendi"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim silindi"])},
    "need-to-enter-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim adı girmelisiniz."])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç birim bulunmuyor"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden adlandır"])},
    "renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim adı değiştirildi."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinizin birim hiyerarşisi."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimler"])}
  },
  "divvyDrieBrowser": {
    "add-selected-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili dosyaları ekle"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "the-folder-is-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasör boş."])}
  },
  "divvyDriveSignIn": {
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "User-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Adı"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "remember-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni hatırla"])},
    "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])}
  },
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
  "editHeader": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasarım"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokümanlar"])},
    "further": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gözden geçirme"])},
    "signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılar"])},
    "tabname-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasarım"])},
    "tabname-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokümanlar"])},
    "tabname-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gözden Geçirme"])},
    "tabname-signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılar"])}
  },
  "enterCompanyInformation": {
    "company-trade-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Ticari Unvanı"])},
    "enter-the-following-information-to-switch-to-the-corporate-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal hesaba geçiş için aşağıdaki bilgileri giriniz."])},
    "you-can-write-the-full-commercial-name-of-your-company-example-seneka-software-hardware-tic-commitment-and-san-ltd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinizin tam ticari adını yazabilirsiniz. Örnek: Seneka Yazılım Donanım Tic. Taah. ve San. Ltd."])},
    "your-company-name-is-included-in-the-application-and-in-the-e-mail-sent-for-e-signing-example-seneka-software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinizin adı uygulama içerisinde ve e-imza atılması için gönderilen e-postada yer alır. Örnek: Seneka Yazılım"])}
  },
  "enterPassword": {
    "2fa-subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " adlı işlem için telefonunuza gelen kodu giriniz."])},
    "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yap"])},
    "Please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyin"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştir"])},
    "enter-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi girin"])},
    "forgot-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi mi unuttunuz?"])},
    "mail-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Postanıza şifre sıfırlama linki gönderilemedi. Lütfen tekrar deneyiniz."])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "reset-password-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdiğiniz e-posta sistemde kayıtlı ise, şifre sıfırlama linki e-posta adresinize gönderilmiştir."])},
    "reset-password-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdiğiniz e-posta sistemde kayıtlı ise, şifre sıfırlama linki e-postanıza gönderilmiştir."])},
    "reset-password-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Postanıza şifre sıfırlama linki gönderilemedi. Lütfen tekrar deneyiniz."])},
    "reset-password-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre sıfırlama linki e-posta adresinize gönderilmiştir."])},
    "reset-password-send-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdiğiniz e-posta sistemde kayıtlı ise, şifre sıfırlama linki e-postanıza gönderilmiştir."])},
    "tfa-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Adımlı Kimlik Doğrulama"])},
    "use-for-free-by-registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kayıt olarak ücretsiz kullanın"])}
  },
  "enterPhoneNumber": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "document-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge İmzalandı"])},
    "enter-your-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil imzanızın tanımlı olduğu, 10 haneli cep telefonu numaranızı giriniz."])},
    "ex-5xxxxxxxxxx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örn: 5XXXXXXXXX"])},
    "failed-to-get-fingerprint-value-for-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem için parmak izi değeri alınamadı."])},
    "fingerprint-for-operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem için parmak izi aşağıda gösterilmiştir. Telefonunuzda da aynı parmak izini görmeniz gerekiyor."])},
    "mobile-signature-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil imza işlemi"])},
    "mobile-signing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil İmzalama"])},
    "please-enter-a-valid-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir telefon numarası giriniz."])},
    "please-follow-the-instructions-on-your-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen telefonunuzdaki yönergeleri takip ediniz."])},
    "sign-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil İmzala"])},
    "the-process-is-starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem başlatılıyor."])},
    "turk-telekom-coming-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türk Telekom"])},
    "vodafone-coming-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vodafone"])},
    "you-signed-the-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeyi imzaladınız."])},
    "your-e-signature-is-equivalent-to-a-wet-signature-under-the-e-signature-law-no-5070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmış olduğunuz e-imza 5070 sayılı e-imza kanunu kapsamında ıslak imza ile eşdeğerdir."])},
    "your-gsm-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM Numaranız"])},
    "your-gsm-operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM Operatörünüz"])},
    "we-have-sent-you-an-email-regarding-the-action-you-took": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerçekleştirdiğiniz işlemle ilgili size bir e-posta gönderdik."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "an-error-occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata oluştu!"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
  "error-fetching-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlar getirilirken hata oluştu"])},
  "errorHelper": {
    "ECONNABORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ERR_BAD_OPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ERR_BAD_OPTION_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ERR_BAD_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ERR_BAD_RESPONSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ERR_CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ERR_DEPRECATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ERR_FR_TOO_MANY_REDIRECTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çok fazla yönlendirme."])},
    "ERR_NETWORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ETIMEDOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "finishJobSendKep": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posta içeriği"])},
    "receipents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcılar"])}
  },
  "flowComponent": {
    "contactType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak türü"])},
    "notes-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçle ilgili not ve ekler"])},
    "notes-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not ve Ekler"])},
    "ownerDepartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ait olduğu birim"])},
    "whoCanSee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimler görebilir"])},
    "workingCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalıştığı şirket"])}
  },
  "flowContextMenu": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
    "create-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyasını oluştur"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "delete-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosyaları sil"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden adlandır"])},
    "send-with-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP'le gönder"])},
    "send-with-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP ile gönder"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüle"])}
  },
  "flowDocuments": {
    "add-attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek Ekle"])},
    "add-file-from-divvydrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DivvyDrive'dan dosya ekle"])},
    "add-file-from-my-computer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgisayarımdan dosya ekle"])},
    "are-you-sure-you-want-to-delete-the-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeyi silmek istediğinizden emin misiniz?"])},
    "at-least-one-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az bir doküman yüklemelisiniz."])},
    "authentication-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "cannot-access-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucuya erişilemedi"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "delete-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeyi sil"])},
    "delete-error-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen hatalı dokümanları silin."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata oluştu"])},
    "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata oluştu. Kod: "])},
    "eyazisma-attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma Paketi Ekleri"])},
    "eyazisma-attachments-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma paketine ek olarak eklenmesi gereken dosyalar."])},
    "include-documents-to-be-signed-after-adding-the-necessary-documents-you-can-proceed-to-the-next-step-with-the-next-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalanacak dokümanları ekleyin. Gerekli dokümanları ekledikten sonra ileri butonu ile sonraki adıma geçebilirsiniz."])},
    "invalid-upload-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleme sonucu geçersiz."])},
    "no-attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç ek eklenmemiş. Yukarıdaki butonu kullanarak ek ekleyebilirsiniz."])},
    "no-documents-have-been-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç belge yüklenmemiş"])},
    "no-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç yok"])},
    "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 sayfa"]), _normalize([_interpolate(_named("count")), " sayfa"])])},
    "server-could-not-process-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu dosyayı işleyemedi"])},
    "session-ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum sonlanmış"])},
    "supported-file-types-pdf-word-excel-and-powerpoint-and-image-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desteklenen dosya türleri: Pdf, Word, Excel ve Powerpoint ve resim dosyalarıdır."])},
    "upload-result-value-is-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleme sonucu geçersiz bir değer."])},
    "wait-for-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm dokümanların yüklenmesi bekleyin."])},
    "you-can-upload-your-documents-that-you-want-signed-by-using-one-of-the-options-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki seçeneklerden birini kullanarak imzalanmasını istediğini belgelerinizi yükleyebilirsiniz"])},
    "notes-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Notları"])},
    "notes-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçteki diğer kişiler için notlar ekleyebilirsiniz. Dosya paylaşmak için notunuza ek ekleyebilirsiniz."])},
    "ekler-ve-notlar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekler ve Notlar"])}
  },
  "flowItemSearch": {
    "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Soyad"])},
    "search-results-were-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama sonucu kayıt bulunamadı."])}
  },
  "pagingComponent": {
    "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sayfa"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kayıt"])}
  },
  "searchFlows": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramak için kriterlerinizi giriniz."])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu"])},
    "created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma Tarihi"])},
    "last-operation-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son İşlem Tarihi"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket"])},
    "assigned-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimde"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])}
  },
  "flowItems": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
    "add-people-to-sign-the-document-the-document-will-be-sent-to-the-relevant-persons-in-the-sequence-of-steps-shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge gösterilen adım sırası ile ilgili kişilere imzalamaları için gönderilecektir."])},
    "add-people-to-sign-the-document-the-document-will-be-sent-to-the-relevant-persons-to-sign-in-the-order-shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeyi imzalayacak kişileri ekleyin. Belge gösterilen sıra ile ilgili kişilere imzalamaları için gönderilecektir."])},
    "add-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi Ekle"])},
    "add-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacı ekle"])},
    "add-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adım Ekle"])},
    "advanced-process-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş süreçler ek modül olarak alınabilir."])},
    "advanced-processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş süreçler"])},
    "advanced-processes-are-available-for-pay-as-you-go-personal-and-pay-as-you-go-corporate-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş süreçler ek modül olarak alınabilir."])},
    "are-you-sure-you-want-to-delete-the-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacıyı silmek istediğinizden emin misiniz?"])},
    "atleast-one-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az bir imzacı olmalıdır."])},
    "authority-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Seviyesi"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "create-new-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kayıt oluştur"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "delete-the-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacıyı sil"])},
    "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta"])},
    "email-address-of-the-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza atacak kişinin e-posta adresi"])},
    "email-and-names-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacıların tümü için e-posta adresi ve ad soyad bilgisinin girilmesi gerekmektedir."])},
    "eyazisma-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma paketinin alıcılarını aşağıdaki alana giriniz."])},
    "eyazisma-target-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef silindi."])},
    "eyazisma-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma Paketi Hedefleri"])},
    "job-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem silindi."])},
    "jobs-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başarıyla tamamladığında süreçle ilgili yapılacak işlemleri belirleyebilirsiniz."])},
    "jobs-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Tamamlanınca Yapılacak İşlemler"])},
    "name-and-surname-of-the-person-to-sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza atacak kişinin adı soyadı"])},
    "no-eyazisma-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma paketi için en az bir hedef girmelisiniz."])},
    "no-eyazismatarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma paketi için hiç hedef eklenmemiş. Yukarıdaki kutucuktan arama yapabilir veya aşağıdaki butonu kullanarak yeni kayıt oluşturabilirsiniz."])},
    "no-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem tanımlanmamış. Aşağıdaki butonları kullanarak yeni işlem tanımlayabilirsiniz."])},
    "once-you-have-identified-the-signatories-you-can-proceed-to-the-next-step-with-the-next-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacıları belirledikten sonra ileri butonu ile sonraki aşamaya geçebilirsiniz."])},
    "operation-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Türü"])},
    "send-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP ile Gönder"])},
    "send-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP ile Gönder"])},
    "send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta Gönder"])},
    "signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılar"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adım"])},
    "to-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş moda geç"])},
    "to-basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basit moda geç"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüle"])},
    "with-advanced-processes-you-can-add-more-than-one-person-in-the-same-step-and-define-transaction-types-such-as-approval-and-monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş süreçlerle aynı adımda birden çok kişi ekleyebilir, onay, izleme gibi işlem tipleri tanımlayabilirsiniz."])},
    "you-can-visit-the-support-page-for-more-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla bilgi almak için destek sayfasını ziyaret edebilirsiniz."])}
  },
  "flowList": {
    "add-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgelerinizi imzalamak veya imzalatmak için yukarıdaki butonu kullanarak yeni süreç oluşturabilirsiniz"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "clear-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreyi temizle"])},
    "create-and-view-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Oluşturma ve İzleme"])},
    "deparment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim"])},
    "last-operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son İşlem"])},
    "list-flows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Listeleme"])},
    "no-flow-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili kriterlere uygun akış bulunmuyor"])},
    "no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç süreç oluşturulmamış"])},
    "no-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlığı belirlenmemiş"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAHİP"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel"])},
    "select-departmant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim Seçme"])},
    "select-department-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçlerini görüntülemek istediğiniz birim seçiniz."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanma"])},
    "status-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
    "view-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili birimin süreçlerini görüntüle"])}
  },
  "flowMetaData": {
    "5-days-after-your-draft-documents-(if-the-process-is-not-started)-are-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak belgeleriniz (süreç başlatılmaz ise) oluşturulduktan 5 gün sonra,"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
    "according-to-your-current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut planınıza göre,"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyala              "])},
    "created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulma tarihi"])},
    "deletion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece ait belgelerin silineceği tarih"])},
    "documents-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeler Silinmiş"])},
    "download-signed-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmzalı Belgeyi İndir"])},
    "empty-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlığı belirlenmemiş"])},
    "flowMetaData.deletion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eee"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aç"])},
    "owner-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ait olduğu departman"])},
    "process-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Bilgileri"])},
    "signature-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza türü"])},
    "size-of-files-in-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürecin sistemdeki boyutu"])},
    "size-of-original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüklenen dosyaların boyutu"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlatılma tarihi"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece ait üst veri bilgileri."])},
    "verification-code-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama kodu kopyalandı."])},
    "verification-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama yöntemi"])},
    "verify-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama kodu"])},
    "you-can-upgrade-to-paid-plans-to-have-your-processes-stored-in-the-cloud-indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçlerinizin süresiz olarak bulutta saklanması için ücretli planlara geçebilirsiniz."])},
    "your-signed-documents-are-automatically-deleted-3-days-after-they-are-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalanmış belgeleriniz ise imzalandıktan 3 gün sonra otomatik olarak silinir."])}
  },
  "flowMetaDataLarge": {
    "5-days-after-your-draft-documents-are-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak belgeleriniz oluşturulduktan 5 gün sonra,"])},
    "5-days-after-your-draft-documents-if-the-process-is-not-started-are-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak belgeleriniz (süreç başlatılmaz ise) oluşturulduktan 5 gün sonra,"])},
    "according-to- your-current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut planınıza göre,"])},
    "according-to-your-current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut planınıza göre,"])},
    "documents-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Belgeler Silinmiş)"])},
    "process-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç bilgileri"])},
    "process-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece ait üst veri bilgileri."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç bilgileri"])},
    "verification-code-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama kodu kopyalandı."])},
    "you-can-upgrade-to-paid-plans-to-have-your-processes-stored-in-the-cloud-indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçlerinizin süresiz olarak bulutta saklanması için ücretli planlara geçebilirsiniz."])},
    "your-signed-documents-are-automatically-deleted-3-days-after-they-are-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalanmış belgeleriniz ise imzalandıktan 3 gün sonra otomatik olarak silinir."])}
  },
  "flowNoteItem": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "no-note-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not girilmemiş."])}
  },
  "flowNotes": {
    "add-note-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notunuzu yazın..."])},
    "addattachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek ekle"])},
    "authenticationerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "cannotaccessserver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucuya erişilemedi"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
    "errormessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata oluştu. Kod: "])},
    "erroroccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata oluştu"])},
    "invaliduploadresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleme sonucu geçersiz"])},
    "noflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akış değeri boş"])},
    "nonote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç not eklenmemiş. Yeni not veya ek ekleyebilirsiniz."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "servercouldnotprocessfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu dosyayı işleyemedi"])},
    "sessionended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum sonlanmış"])},
    "uploadresultvalueisinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleme sonucu geçersiz bir değer."])}
  },
  "flowOperations": {
    "dateoperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih/İşlem"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaylar"])},
    "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemler"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra"])},
    "transacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemi Yapan"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekle"])}
  },
  "tagSelector": {
    "no-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket atanmamış"])},
    "add-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni etiket ekle"])}
  },
  "flowOperationsLarge": {
    "added-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketler"])},
    "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["belgeyi onayladı"])},
    "cancelled-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci iptal etti"])},
    "created-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci oluşturdu"])},
    "deleted-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama üzerinden dosyaları sildi"])},
    "download-signed-file-from-application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmzalı belgeyi indirdi (Uygulama içerisinden)"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarihinde"])},
    "opened-sign-portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza portalini açtı"])},
    "person-incharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem yapacak kişi"])},
    "person-to-sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza atacak kişi"])},
    "rope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
    "send-to-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki kişiye gönderdi"])},
    "signed-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["belgeyi imzaladı"])},
    "signed-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["belgeyi mobil imzaladı"])},
    "started-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci başlattı"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçle ilgili yapılan tüm işlemler."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem geçmişi"])},
    "user-incharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem yapacak kullanıcı"])},
    "user-to-sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza atacak kullanıcı"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddetti"])}
  },
  "flowOperationsTable": {
    "additional-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ek bilgi"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçle ilgili yapılan tüm işlemler."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem geçmişi"])},
    "transaction-maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemi Yapan"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan"])},
    "browser-ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarayıcı/IP"])}
  },
  "flowsComponent": {
    "arama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama..."])},
    "cannot-create-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürecin kopyası oluşturulamadı."])},
    "copy-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürecin bir kopyası oluşturulmuştur."])},
    "create-and-view-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Oluşturma ve İzleme"])},
    "create-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Oluşturma"])},
    "create-for-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel süreç oluştur"])},
    "create-fro-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili birim için süreç oluştur"])},
    "create-new-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni süreç oluştur"])},
    "flow-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç no"])},
    "kontagin-ait-olacagi-birimi-seciniz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontağın Ait Olacağı Birimi Seçiniz"])},
    "open-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenekler"])},
    "process-title-not-specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlığı belirlenmemiş"])},
    "processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçler"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama"])},
    "select-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürecin Ait Olacağı Birimi Seçiniz"])},
    "show-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş seçenekleri göster"])},
    "sub-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci bir birim adına oluşturuyorsanız aşağıdan yetkinizin olduğu bir birim seçiniz."])},
    "sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel süreç oluşturmak için Kişisel Süreç Oluştur seçeneğini seçiniz."])},
    "you-can-see-the-details-here-by-selecting-the-process-on-the-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sol taraftan süreç seçerek detayları burada görebilirsiniz."])},
    "showing-search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama sonuçları gösteriliyor"])}
  },
  "flowsWillBeDeletedReport": {
    "date-to-be-cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SİLİNECEĞİ TARİH"])},
    "date-to-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siliniceği tarih"])},
    "error-fetching-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlar getirilirken hata oluştu"])},
    "no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakın zamanda silinecek süreciniz bulunmuyor"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç"])},
    "process-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç No"])},
    "process-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAŞLIK"])},
    "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeleri silinecek süreçler"])},
    "the-document-deletion-process-is-applied-to-free-accounts-and-there-is-no-document-deletion-in-the-account-you-are-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge silme işlemi ücretsiz hesaplara uygulanmakta olup bulunduğunuz hesapta belge silme işlemi yapılmamaktadır."])},
    "this-feature-is-not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu özellik kullanılamaz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinecek Süreçler"])}
  },
  "homeView": {
    "account-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız doğrulanamadı. Lütfen tekrar deneyiniz."])},
    "after-completing-the-verification-process-you-can-continue-to-use-the-system-by-using-the-check-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama işlemini yaptıktan sonra Kontrol Et butonunu kullanarak sistemi kullanmaya devam edebilirsiniz."])},
    "check-it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol et"])},
    "departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departmanlar"])},
    "email-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar e-posta gönder"])},
    "failed-to-send-verification-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama e-postası gönderilemedi"])},
    "processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçler"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlar"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
    "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu kapat"])},
    "signatureportal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza Portali"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek"])},
    "verification-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama e-postası gönderilmiştir. Lütfen e-postanızı kontrol ediniz"])},
    "we-sent-you-an-email-with-the-subject-verify-the-email-address-you-use-for-my-confirmations-you-need-to-verify-your-e-mail-address-by-clicking-the-link-in-the-e-mail-we-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size 'ONAYLARIM için kullandığınız e-posta adresini doğrulayın' konulu bir e-posta göndermiştik. Gönderdiğimiz e-postadaki linke tıklayarak e-posta adresinizi doğrulmanız gerekiyor."])},
    "you-can-use-the-send-email-again-button-to-receive-the-verification-email-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama e-postasını tekrar almak için Tekrar e-Posta Gönder butonunu kullanabilirsiniz."])},
    "your-account-has-been-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız doğrulandı."])},
    "your-e-mail-address-is-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta Adresiniz Doğrulanmamış"])}
  },
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
  "kepAccountAddEdit": {
    "account-a-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba bir isim verin"])},
    "account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Adı"])},
    "app-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Şifresi"])},
    "application-name-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama adı girin"])},
    "applicaton-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Adı"])},
    "authorized-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkili Kodu"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "citizienship-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T.C. kimlik numarasını girin"])},
    "delete-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Hesabını sil"])},
    "enter-account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap adı girin"])},
    "enter-kep-mail-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP posta adresini girin"])},
    "enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre girin"])},
    "enter-password-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola girin"])},
    "enter-the-authorized-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkili kodu girin"])},
    "enter-the-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre girin"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "is-it-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Mi"])},
    "kep-account-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Hesabı Güncelleme"])},
    "kep-accounts-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP hesapları yönetimi"])},
    "kep-postal-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Posta Adresi"])},
    "kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Sağlayıcısı"])},
    "new-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni KEP Hesabı Tanımlama"])},
    "new-kep-account-by-filling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri doldurarak yeni KEP hesabı tanımlayabilirsiniz."])},
    "owner-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Sahibi"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
    "password-sifre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "select-kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP sağlayıcısı seçiniz..."])},
    "tc-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TC Kimlik No"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "update-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Hesabı Güncelleme"])},
    "update-kep-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri değiştirerek KEP bilgilerini güncelleyebilirsiniz."])}
  },
  "kepAccountEdit.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "kepAccountManagement": {
    "account-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap silindi."])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "add-new-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni KEP hesabı ekle"])},
    "all-kep-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumunuzdaki KEP hesapları"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "delete-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP hesabı silme"])},
    "do-you-want-to-permanently-delete-the-selected-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili KEP hesabını kalıcı olarak silmek istiyor musunuz?"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "identification-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlama yapıldı"])},
    "kep-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Hesapları"])},
    "kep-accounts-unauthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP hesaplarını görmek için gerekli yetkiniz bulunmuyor"])},
    "kep-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Adresi"])},
    "kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Sağlayıcısı"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "new-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak yeni KEP hesabı tanımlayabilirsiniz"])},
    "no-kep-account-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç KEP hesabı tanımlanmamış"])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
    "update-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme yapıldı\n"])},
    "you-are-not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için yetkiniz yok."])},
    "you-can-define-a-new-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak yeni KEP hesabı tanımlayabilirsiniz. Mevcut planınızda KEP gönderimi yapamamakla beraber örnek gösterim amacıyla bu modül açık bırakılmıştır"])}
  },
  "kepAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP adresi"])},
  "kepReport": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TARİH"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş Tarihi"])},
    "kep-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP No"])},
    "ne-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt bulunamadı."])},
    "no-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlığı belirlenmemiş"])},
    "receipents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALICILAR"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Gönder"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Dene"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçiniz..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
    "send-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP gönderilemedi."])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GÖNDEREN HESAP"])},
    "sent-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP başarıyla gönderildi."])},
    "sign-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzala ve Gönder"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç Tarihi"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP gönderim raporu"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Raporu"])},
    "title-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAŞLIK"])},
    "title-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu"])},
    "unique-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç No"])},
    "need-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göndermem gerekenler"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "no-right-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP işlemlerini görmek için gerekli yetkiniz bulunmuyor"])}
  },
  "sftpReport": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TARİH"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş Tarihi"])},
    "kep-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP No"])},
    "ne-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt bulunamadı."])},
    "no-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlığı belirlenmemiş"])},
    "receipents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALICILAR"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Gönder"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Dene"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçiniz..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
    "send-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP gönderilemedi."])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GÖNDEREN HESAP"])},
    "sent-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP başarıyla gönderildi."])},
    "sign-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzala ve Gönder"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç Tarihi"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP gönderim raporu"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Raporu"])},
    "title-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAŞLIK"])},
    "title-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu"])},
    "unique-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç No"])},
    "need-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göndermem gerekenler"])},
    "sftp-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Hesabı"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])}
  },
  "languageSelector": {
    "language-change-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil ayarı değiştirilemedi."])},
    "language-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil ayarı değiştirildi."])}
  },
  "licenseCalculator": {
    "1-user-account-fee-until-license-expiry-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisans Bitiş Tarihine Kadar 1 Kullanıcı Hesabı Ücreti"])},
    "1-user-account-monthly-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Kullanıcı Hesabı Aylık Ücreti"])},
    "number-of-months-remaining-to-license-expiry-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisans Bitiş Tarihine Kalan Ay Sayısı"])},
    "number-of-user-accounts-to-take": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınacak Kullanıcı Hesabı Sayısı"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fİyat"])},
    "price-calc-1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lisans Bitiş Tarihine Kadar ", _interpolate(_named("newLicenseCount")), " Kullanıcı Hesabı Ücreti"])},
    "productservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün/Hİzmet"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirleğiniz sayıda ek kullanıcı hesabı alabilirsiniz."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek Kullanıcı Hesabı Alma"])},
    "total-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Fiyat"])},
    "vat-18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KDV (%20)"])}
  },
  "licenseCount": {
    "available-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılabilir Kullanıcı Hesabı Sayısı"])},
    "buy-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisans satın al"])},
    "the-number-of-user-accounts-you-have-and-are-in-use-are-shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahip olduğunuz ve kullanımda olan kullanıcı hesap sayıları gösterilir."])},
    "total-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Kullanıcı Hesabı"])},
    "total-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Kullanıcı Sayısı"])},
    "used-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılan Kullanıcı Hesabı"])},
    "user-accounts-usage-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı hesapları kullanım durumu"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücretsiz planlarda ek kullanıcı lisansı alınamaz. Ek kullanıcı lisansı almak için üst plana geçebilirsiniz."])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta"])},
    "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu e-posta sistemde kayıtlı değil"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı e-posta adresinizi yazın"])},
    "forgot-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifremi unutum"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kayıt olarak ücretsiz kullanın"])},
    "remember-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni hatırla"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızla giriş yapın"])},
    "title-for-auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya Microsoft hesabınızla giriş yapın"])},
    "want-to-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt olarak belgelerinizin ücretsiz olarak e-imzalanmasını ister misiniz"])}
  },
  "moneyTransfer": {
    "a-remittance-record-has-been-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale kaydı oluşturuldu."])},
    "after-performing-the-transfer-user-accounts-will-be-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havaleyi gerçekleştirdikten sonra kullanıcı hesapları aktif olacaktır."])},
    "after-you-make-the-transfer-your-credit-will-be-automatically-loaded-into-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havaleyi gerçekleştirdikten sonra krediniz hesabınıza otomatik olarak yüklenecektir."])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Tutarı"])},
    "create-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale kaydı oluştur"])},
    "iban-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Bilgileri"])},
    "immediately-after-the-transfer-your-credit-balance-will-be-loaded-and-an-e-mail-will-be-sent-to-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale işleminden hemen sonra kredi bakiyeniz yüklenecek ve size e-posta gönderilecektir."])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan Mal/Hizmet"])},
    "please": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lütfen aşağıdaki banka hesaplarından birine <span class=\"font-semibold\">", _interpolate(_named("transferCode")), "</span> açıklaması ile <span class=\"font-semibold\">", _interpolate(_named("priceString")), "</span> havale/eft yapınız."])},
    "receipt-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatura Bilgileri"])},
    "recipient-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcı Adı"])},
    "sub-title-credit-deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale veya EFT yaptıktan sonra krediniz hesabınıza yüklenir."])},
    "sub-title-else": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale veya EFT yaptıktan planınız yükseltilir."])},
    "sub-title-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale veya EFT yaptıktan sonra kullanıcı hesapları aktif olacaktır."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale/EFT ile Ödeme"])},
    "when-you-create-a-remittance-record-you-will-be-sent-an-e-mail-containing-the-remittance-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale kaydı oluşturduğunuz da size havale bilgilerini içeren bir e-posta gönderilecektir."])},
    "your-plan-is-upgraded-after-you-make-the-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havaleyi gerçekleştirdikten sonra planınız yükseltilir."])}
  },
  "moneyTransferHistory": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TARİH"])},
    "e-mail-sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Address")), " adresine fatura gönderim işlemi alınmıştır."])},
    "email-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturayı e-Postala"])},
    "no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale/EFT kaydınız bulunmuyor"])},
    "no-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ödeme için fatura henüz oluşturulmamış. En kısa zamanda e-fatura oluşturularak size e-posta atılacaktır."])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "no-right-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale/EFT işlemlerini görmek için gerekli yetkiniz bulunmuyor"])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için gerekli yetkiniz bulunmamaktadır."])},
    "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem"])},
    "plan-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu planda Havale/EFT işlemleri bulunmamaktadır"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm havale/EFT kayıtlarınızı burada bulabilirsiniz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale ve EFT'ler"])}
  },
  "myDepartmentRights": {
    "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki"])},
    "creation-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulma Tarİhİ"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BİRİM"])},
    "my-dep-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimlerdeki Rollerim"])},
    "no-enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim rolleri özelliği sadece kurumsal planlarda kullanılabilir."])},
    "no-organization-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir birimde yetkiniz bulunmuyor"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimler için size atanmış roller listelenmiştir"])},
    "subunits-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Birimler Dahil"])},
    "subunits-not-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Birimler Dahil Değil"])}
  },
  "myOrganizationalRights": {
    "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkİ"])},
    "creation-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulma Tarİhİ"])},
    "error-fetching-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlar getirilirken hata oluştu"])},
    "my-corporate-powers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal Yetkilerim"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal yetkiniz bulunmuyor"])},
    "your-corporate-authorizations-are-listed-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal yetkileriniz aşağıda listelenmiştir"])}
  },
  "uimSetting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzaktan İmza"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmzanız yanınızda olmadan e-imza atın"])}
  },
  "myProfileInfo": {
    "account-opening-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Açılış Tarihi"])},
    "account-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Türü"])},
    "add-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştir"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Adı"])},
    "company-official": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Yetkilisi"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılan imza"])},
    "delete-my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabımı Sil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilim"])},
    "my-signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalarım"])},
    "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Soyad"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep Telefonu"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoğraf"])},
    "photograph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoğraf"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre sıfırlama linki gönder"])},
    "set-as-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu kullan"])},
    "your-information-in-the-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistemdeki bilgileriniz"])},
    "connect-to-microsoft-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft hesabına bağla"])},
    "2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki Aşamalı Kimlik Doğrulama"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
  "noSignerApp": {
    "after-install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulumu tamamladıktan sonra aşağıdaki yenile butonuna basabilirsiniz."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "can-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza aracını bilgisayarınıza daha önce kurduysanız yandaki butonu kullanarak açabilirsiniz."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndir"])},
    "download-esignature-tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza Aracını İndir"])},
    "download-for-macos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["macOS için İndir"])},
    "download-for-windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows için İndir"])},
    "esignature-tool-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza Aracı Bulunamadı"])},
    "install-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza aracını bilgisayarınıza kurmak için yandaki butonu kullanabilirsiniz."])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aç"])},
    "open-esignature-tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza Aracını Aç"])},
    "press-refresh-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki seçeneklerden birini tamamladıktan sonra yenile düğmesine basabilirsiniz"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenile"])},
    "maybeupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm işlemleri yapmanıza rağmen imzanız bulunamadıysa, ONAYLARIM e-İmza aracının aşağıdaki sürüm olduğundan emin olunuz. Sizdeki sürüm eski ise bilgisayarınızdan uygulamyı kaldırıp yeni sürümü yükleyiniz."])}
  },
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
  "onlyMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece ben"])},
  "organizationRoleAddEdit": {
    "add-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri doldurarak bir kullanıcıya kurum genelinde yetki tanımlayabilirsiniz."])},
    "add-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kurumsal Yetki Tanımlama"])},
    "continue-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme İşlemine Devam Et!"])},
    "institutional-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal Yetki"])},
    "is-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Mi"])},
    "update-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri değiştirerek tanımlanmış yetkiyi güncelleyebilirsiniz."])},
    "update-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal Yetki Güncelleme"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
    "what-are-corporate-powers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal yetkiler nedir"])}
  },
  "organizationRoles": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlama yapıldı"])},
    "create-new-corporate-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kurumsal yetki oluştur"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt silindi"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "feature-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal yetki tanımlamaları sadece kurumsal hesaplar tarafından kullanılabilir"])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç kurumsal yetki tanımı bulunmuyor"])},
    "no-record-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak yeni kurumsal yetki tanımlayabilirsiniz"])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim."])},
    "no-right-error-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal yetkileri görmek için gerekli yetkiniz bulunmuyor"])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurum geneli yetkileri yönetebilirsiniz."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurum geneli yetkiler"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme yapıldı"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])}
  },
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
  "payForFlow": {
    "you-can-start-the-process-by-making-a-payment-with-one-of-the-following-payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki ödeme yöntemlerinden biriyle ödeme yapıp süreci başlatabilirsiniz."])}
  },
  "paymentOptionNothing": {
    "continue-without-paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücret ödemeden devam et"])},
    "documents-are-deleted-3-days-after-the-completion-of-the-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürecin tamamlanmasından 3 gün sonra belgeler silinir."])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜCRETSİZ"])},
    "start-your-process-at-no-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiçbir ücret ödemeden sürecinizi başlatın."])},
    "tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TL"])},
    "when-your-process-is-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreciniz tamamlandığında e-imzalı belge size e-posta ile iletilir. Ayrıca e-imza belgeyi sistem içerisinden de indirebilirsiniz"])}
  },
  "paymentOptionPayOneTimeFee": {
    "page-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Bedeli"])},
    "piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adet"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiyat"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet"])},
    "service-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet Bedeli"])},
    "signature-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza Bedeli"])},
    "tier0-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı ile öde"])},
    "tier0-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci başlatmak için bir seferlik ödeme yapın. İhtiyacınız kadar ödeyin."])},
    "tier0-feature-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme adımında fiyata %20 KDV eklenecektir."])},
    "tier0-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEK SEFERLİK ÖDEME"])},
    "too-much-page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["** Sayfa sayısı ", _interpolate(_named("v1")), " olduğu için hesaplamada sayfa sayısı ", _interpolate(_named("v2")), " olarak alınmıştır."])},
    "unit-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim Fiyat"])}
  },
  "paymentOptionPayWithCredit": {
    "buy-credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi satın al"])},
    "insufficient-credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeterli kredinizi bulunmamaktadır. Kredi Yükle linki ile kredi yükleyebilirsiniz."])},
    "required-credit-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli Kredi Miktarı"])},
    "tier1-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut kredim ile öde"])},
    "tier1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her bir süreç için ödeme yapmak yerine kredi yükleyin ve her bir süreç için sadece 10,98 TL ödeyin."])},
    "tier1-feature-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her bir süreç için ödeme yapmak yerine, hesabınıza kredi yükleyerek sabit ücret ile işlemlerinizi yapabilirsiniz."])},
    "tier1-feature-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sayede daha uygun fiyatla işlem yapmanın yanı sıra her seferinde ödeme işlemi yapmadığınız için vakit de kazanırsınız."])},
    "tier1-feature-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme adımında fiyata %20 KDV eklenecektir."])},
    "tier1name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KREDİDEN ÖDEME"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her bir süreç için ödeme yapmak yerine kredi yükleyin ve her bir süreç için sadece "])},
    "title-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödeyin."])},
    "your-current-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Krediniz"])}
  },
  "personInfo": {
    "citizenshipno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatandaşlık no"])},
    "duty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görev"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk Ad"])},
    "persontitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unvan"])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön Ek"])},
    "secondname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkinci ad"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel bilgileriniz."])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi Bilgisi"])}
  },
  "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyin"])},
  "register": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt ol"])},
    "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta adresi"])},
    "email-already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu e-posta sistemde kayıtlı bir kullanıcıya ait."])},
    "enter-your-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta adresinizi yazın"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "sign-in-with-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hesabınızla giriş yapın"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt olun"])},
    "title-for-auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya Microsoft hesabınızla üye olun"])}
  },
  "renameFlow": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "current-process-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut süreç başlığı"])},
    "new-process-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni süreç başlığı"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece yeni bir başlık tanımlayabilirsiniz."])},
    "the-process-title-has-been-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlığı değiştirildi."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Bağlığı Değiştirme"])}
  },
  "renameOrganization": {
    "company-legal-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Ticari Unvanı"])},
    "company-legal-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket ticari unvanı  girmelisiniz."])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Adı"])},
    "company-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket adı girmelisiniz."])},
    "company-name-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinizin adı uygulama içerisinde ve e-imza atılması için gönderilen e-postada yer alır. Örnek: Seneka Yazılım"])},
    "compnay-legal-name-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinizin tam ticari adını yazabilirsiniz. Örnek: Seneka Yazılım Donanım Tic. Taah. ve San. Ltd."])},
    "legal-name-renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unvan değiştirildi."])},
    "renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket adı değiştirildi."])},
    "sub-title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri değiştirerek şirket adını güncelleyebilirsiniz."])},
    "sub-title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri doldurarak şirket ticari unvanını güncelleyebilirsiniz."])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Adı Değiştirme"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket Ticari Ünvanı Değiştirme"])}
  },
  "reportsView": {
    "kep-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Raporu"])},
    "sftp-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Raporu"])},
    "module-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modül Seçimi"])},
    "processes-to-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinecek Süreçler"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlar"])}
  },
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(zorunlu)"])},
  "resetPasswordComponent": {
    "Failed-to-set-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre belirleme işlemi yapılamadı. Lütfen tekrar deneyiniz."])},
    "Invalid-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz link. Lütfen e-postadaki linkin tamamını kullandığınızdan emin olun"])},
    "Not-all-password-rules-are-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm şifre kuralları sağlanmadı. Lütfen şifre kurallarına uygun bir şifre belirleyiniz."])},
    "Please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyin"])},
    "The-password-reset-link-could-not-be-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre sıfırlama linki doğrulanamadı. Lütfen tekrar deneyiniz."])},
    "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyarı"])},
    "Your-password-has-been-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreniz değiştirildi. Giriş sayfasına yönledirileceksiniz."])},
    "change-my-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifremi değiştir"])},
    "change-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi değiştirin"])},
    "login-with-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hesabınızla giriş yapın"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Şifre"])},
    "new-password-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Şifre tekrar"])},
    "password-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girilen iki şifre de aynı olmalı."])},
    "password-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az 1 noktalama işareti olmalı."])},
    "password-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre en az 8 karakter uzunluğunda olmalı."])},
    "password-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az bir rakam olmalı."])},
    "password-uppercase-lowercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az bir büyük ve küçük harf olmalı."])}
  },
  "rolesInDepartment": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "add-new-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni rol ekle"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlama yapıldı"])},
    "dont-include-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Birimler Dahil Değil"])},
    "enterprise-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roller modülü sadece kurumsal hesaplar tarafından kullanılabilir"])},
    "include-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Birimler Dahil"])},
    "no-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimde tanımlı rol bulunmuyor"])},
    "no-roles-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak birim için yeni rol tanımlayabilirsiniz"])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
    "reason": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu yetki <span class=\"font-semibold\">", _interpolate(_named("departmentName")), "</span> biriminde tanımlanmış yetkiden dolayı oluşmuştur"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
    "select-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolleri görmek için yukarıdan bir birim seçiniz"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "sub-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("departmentName")), " Birimindeki Roller"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimde Tanımlanmış Roller"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme yapıldı"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
  "scribanEditor": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç"])},
    "corrected-version-of-turkish-letters-in-the-name-of-the-first-file-uploaded-to-the-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece yüklenmiş birinci dosyanın adıda bulunan Türkçe harflerin düzeltilmiş hali"])},
    "dd-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem tarihin gün değeri"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "filename-safe-sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylul_Odeme_Talimati.xlsx"])},
    "filename-safe-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece yüklenmiş birinci dosyanın adıda bulunan Türkçe harflerin düzeltilmiş hali"])},
    "filename-sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül Ödeme Talimatı.xlsx"])},
    "filename-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece yüklenmiş birinci dosyanın adı"])},
    "hh-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem tarihin saat değeri"])},
    "minute-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem tarihin dakika değeri"])},
    "mm-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem tarihin ay değeri"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
    "september-payment-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül Ödeme Talimatı.xlsx"])},
    "september-payment-order1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül Ödeme Talimatı.xlsx"])},
    "ss-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem tarihin saniye değeri"])},
    "subject-safe-sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maas_Odeme_Talimati"])},
    "subject-safe-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürecin konusunun içinde bulunan Türkçe harflerin düzeltilmiş hali"])},
    "subject-sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maaş Ödeme Talimatı"])},
    "subject-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürecin konusu"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon"])},
    "test-it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Et"])},
    "unique-id-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece ait tekil sistem numarası"])},
    "yyyy-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem tarihin yıl değeri"])}
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçiniz"])},
  "selectAddress": {
    "add-new-billing-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni fatura bilgisi ekle"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatura adresi seçin veya ekleyin."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatura Bilgileri"])},
    "you-can-continue-the-process-by-choosing-one-of-your-saved-billing-information-or-you-can-create-a-new-billing-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı fatura bilgilerinizden birini seçerek işleme devam edebilir veya yeni fatura bilgisi oluşturabilirsiniz."])},
    "you-do-not-have-a-defined-address-you-can-define-a-new-address-by-using-the-button-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlanmış adresiniz bulunmuyor. Aşağıdaki butonu kullanarak yeni adres tanımlayabilirsiniz."])}
  },
  "selectCreditPackage": {
    "for-one-process": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Süreç başına ücret <span class=\"whitespace-nowrap\">", _interpolate(_named("priceForOneFlowString")), "</span>"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almak istediğiniz kredi paketini seçiniz."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi Satın Alma"])}
  },
  "selectDepartment": {
    "action-to-be-taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılacak İşlem:"])},
    "required-authorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli Yetkiler:"])}
  },
  "selectPaymentMethod": {
    "credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi Kartı"])},
    "credit-deposit-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartınızla kredi yükleyin, krediniz anında hesabınıza geçsin."])},
    "credit-deposit-sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeleriniz PayTR tarafından işlenmektedir."])},
    "license-credit-card-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartınızla ödeme yapın, ek kullanıcı hesaplarınız anında aktive edilsin."])},
    "license-credit-card-sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeleriniz PayTR tarafından işlenmektedir."])},
    "license-money-transfer-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale işleminden hemen size e-posta gönderilecek ve işlemin onaylanmasıyla ek kullanıcı hesaplarınız aktive edilecektir."])},
    "money-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale/EFT"])},
    "money-transfer-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale işleminden hemen sonra kredi bakiyeniz yüklenecek ve size e-posta gönderilecektir."])},
    "one-tim-fee-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartınızla ödeme yapın ve anında süreciniz başlatılsın."])},
    "one-tim-fee-sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeleriniz PayTR tarafından işlenmektedir."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yöntemi seçiniz."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Yöntemi"])},
    "upgrade-credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartınızla ödeme yapın ve anında dilediğiniz plana geçin."])},
    "upgrade-credit-card-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeleriniz PayTR tarafından işlenmektedir."])},
    "upgrade-money-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale işleminden hemen size e-posta gönderilecek ve işlemin onaylanmasıyla seçtiğiniz plana yükseltileceksiniz."])}
  },
  "selectPlan": {
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal"])},
    "on-premise-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Premise kullanım"])},
    "on-premise-error-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Premise kullanımlarda bu özellik kullanılmaz"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiyatlandırma"])},
    "specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özellikler"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut planınızı görebilir, diğer planlara geçiş yapabilirsiniz."])},
    "switch-to-this-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu Plana Geç"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planlar"])},
    "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu Plana Geç"])},
    "your-current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Planınız"])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "unauthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planları görmek için gerekli yetkiniz bulunmuyor."])}
  },
  "selectSignatureType": {
    "cades-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planınız bu imza türünü desteklemiyor."])},
    "cades-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzun dönem doğrulama destekleyen e-imza atılır."])},
    "e-yazisma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma"])},
    "e-yazisma-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planınız bu imza türünü desteklemiyor."])},
    "e-yazisma-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma paketi oluşturulur."])},
    "pades-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planınız bu imza türünü desteklemiyor."])},
    "pades-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüklediğiniz belgeler otomatik olarak PDF'e dönüştürülür. Uzun dönem PDF imza atılır."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç sonunda atılacak e-imza türünü seçin"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza türü seçin"])}
  },
  "sendKepComponent": {
    "KEP-account-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlı KEP hesabı yok."])},
    "KEP-account-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP hesabı seçiniz."])},
    "KEP-module-include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız KEP modülünü kapsamıyor."])},
    "KEP-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP iletisi gönderilmek üzere kayıt edildi."])},
    "Message-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP mesajının konusunu girin."])},
    "appendices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekler"])},
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için yetkiniz yok."])},
    "buyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcılar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "e-signed-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmzalı Belge"])},
    "from-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderen KEP hesabı"])},
    "message-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj İçeriği"])},
    "only-pay-as-you-use-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu modülü sadece Kurumsal hesaplar kullanabilir."])},
    "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyin"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "save-and-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet ve Gönder"])},
    "sending-KEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Gönderme"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu"])},
    "transaction-performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz gerçekleştirilemedi"])},
    "you-are-not-authorized-to-send-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP gönderme yetkiniz bulunmuyor."])},
    "you-can-send-a-kep-message-by-entering-the-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri girerek KEP iletisi gönderebilirsiniz."])},
    "add-detsis-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaja DETSİS No Ekle"])}
  },
  "sendSftpComponent": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "destination-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef SFTP"])},
    "dummy-textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan ileriki sürümlerde aktif hale getirilecektir."])},
    "no-SFTP-account-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlı SFTP hesabı yok"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not"])},
    "pay-as-you-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu modülü sadece Kurumsal Hesaplar kullanabilir."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "saved-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP gönderme işlemi kayıt edildi."])},
    "select-SFTP-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP hesabı seçiniz..."])},
    "select-the-target-SFTP-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef SFTP hesabı seçiniz."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
    "sending-with-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP ile Gönderme"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP başarıyla gönderildi."])},
    "the-note-you-write-here-will-not-be-forwarded-to-the-bank-it-is-stored-only-as-an-institutional-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buraya yazdığınız not bankaya iletilmez. Sadece kurumsal kayıt olarak saklanır."])},
    "you-are-not-authorized-to-send-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sftp gönderme yetkiniz bulunmuyor."])},
    "you-can-send-an-sftp-message-by-entering-the-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri girerek SFTP iletisi gönderebilirsiniz."])},
    "you-do-not-have-the-necessary-authorization-for-this-operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için gerekli yetkiniz bulunmamaktadır."])},
    "your-acount-does-not-cover-the-SFTP-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız SFTP modülünü kapsamıyor"])}
  },
  "setDepartmentName": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim Silme"])},
    "delete-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimi silmek istiyor musunuz?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim Adı"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim Adı Değiştirme"])},
    "rename-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birime yeni bir ad veriniz."])},
    "set-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim Adı Belirleme"])},
    "set-name-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulacak birimin adını giriniz."])},
    "unit-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimin Adı"])},
    "units-new-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimin Yeni Adı"])},
    "you-can-use-the-button-below-to-delete-the-volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimi silmek için aşağıdaki butonu kullanabilirsiniz."])}
  },
  "setFullname": {
    "an-error-occurred-while-fetching-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgiler getirilirken hata oluştu"])},
    "current-name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ad Soyad"])},
    "name-surname-determination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Soyad Belirleme"])},
    "new-name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Ad Soyad"])},
    "the-name-and-surname-you-entered-here-will-appear-in-the-e-mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili kişilere imzalamaları için gönderdiğiniz belgelere ilişkin e-postalarda buraya girmiş olduğunuz ad soyad görünür."])},
    "you-must-enter-your-name-and-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad girmelisiniz."])},
    "your-name-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad bilginiz değiştirildi."])}
  },
  "setPhoneNumber": {
    "current-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistemde kayıtlı telefon numaranız"])},
    "new-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni cep numaranız"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size gönderilen bildirimler ve iki aşamalı kimlik doğrulama için cep telefonu numaranızı girmelisiniz."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep Telefonu Numaranız"])},
    "your-phone-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep telefonu bilginiz değiştirildi."])},
    "current-gsm-operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistemde kayıtlı GSM operatörünüz"])}
  },
  "setTwoFactorAuthentication": {
    "current-2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdiki iki aşamalı kimlik doğrulama yöntemi"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni 2 Aşamalı kimlik doğrulama yöntemi"])},
    "new-2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni 2 aşamalı kimlik doğrualama yöntemi"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızı daha güvenli hale getirmek için 2 aşamalı kimli doğrulama kullanmanız önerilir."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki Aşamalı Kimlik Doğrulama"])},
    "your-2fa-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 aşamalı kimlik doğrulama yönteminiz değiştirildi."])}
  },
  "settingsView": {
    "api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres Defteri"])},
    "departments-and-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birimler ve Roller"])},
    "eyazisma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma"])},
    "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finansal İşlemler"])},
    "kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP"])},
    "licensing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisanslama"])},
    "module-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modül Seçimi"])},
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilim"])},
    "organizational-rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal Yetkiler"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
    "sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
    "uim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzaktan İmza"])}
  },
  "sftpTargetAddEdit": {
    "active-or-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif mi"])},
    "add-new-sftp-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri doldurarak yeni SFTP hesabı tanımlayabilirsiniz."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "destination-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef Klasör"])},
    "finger-print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parmak İzi"])},
    "folder-creation-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasör Oluşturma Formatı"])},
    "maximum-size-mb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum Boyut (MB)"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "name-creation-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim Oluşturma Formatı"])},
    "new-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SFTP Hesabı Tanımlama"])},
    "open-editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editörü aç"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre "])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "server-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu Adresi"])},
    "server-port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu Portu"])},
    "sftp-accounts-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP hesapları yönetimi"])},
    "sftp-info-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri değiştirerek SFTP bilgilerini güncelleyebilirsiniz."])},
    "sftp-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP sunucusuna bağlantı sağlandı."])},
    "stfp-account-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Hesabı Güncelleme"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Et"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "use-ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSL Kullan"])},
    "user-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Adı"])}
  },
  "sftpTargetManagement": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "add-new-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SFTP hesabı ekle"])},
    "button-define-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak yeni SFTP hesabı tanımlayabilirsiniz"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "define-sftp-open-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki butonu kullanarak yeni SFTP hesabı tanımlayabilirsiniz. Mevcut planınızda SFTP gönderimi yapamamakla beraber örnek gösterim amacıyla bu modül açık bırakılmıştır."])},
    "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap silindi"])},
    "delete-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP hesabını sil"])},
    "do-you-want-to-permanently-delete-the-selected-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili SFTP hesabını kalıcı olarak silmek istiyor musunuz?"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlar getirilirken hata oluştu"])},
    "identification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlama yapıldı"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "no-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem için yetkiniz yok"])},
    "no-permission-operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP hesaplarını görmek için gerekli yetkiniz bulunmuyor"])},
    "operation-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
    "sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP hesapları"])},
    "sftp-account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP hesabı silme"])},
    "sftp-institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumunuzdaki tüm SFTP hesapları."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim"])},
    "undefined-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç SFTP hesabı tanımlanmamış"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme yapıldı."])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyarı"])}
  },
  "appoverActionComponent": {
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapacağınız onay işlemi zaman damgası ile kayıt altına alınacaktır."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylama için hazırsınız"])}
  },
  "approveComponent": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "document-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge imzalandı"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
    "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyiniz"])},
    "the-validation-process-is-starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylama işlemi başlatılıyor."])},
    "the-verification-process-is-starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onay işlemi başlatılıyor."])},
    "we-have-sent-you-an-email-regarding-the-action-you-took": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerçekleştirdiğiniz işlemle ilgili size bir e-posta gönderdik."])},
    "you-can-close-the-window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencereyi kapatabilirsiniz."])},
    "you-have-approved-the-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeyi onayladınız."])},
    "your-transaction-has-been-recorded-with-a-timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapmış olduğunuz işlem zaman damgası ile kayıt altına alınmıştır."])}
  },
  "signerAppExistsButNoCertificate": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "not-plugged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza Kartı Bilgisayara Takılı Değil"])},
    "plug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmzanızı bilgisayarınıza takınız."])},
    "press-refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki işlemleri yaptıktan sonra aşağıdaki yenile düğmesine basabilirsiniz."])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenile"])},
    "replug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmzanız bilgisayarınıza takılı ise çıkartıp tekrar takmayı deneyebilirsiniz."])}
  },
  "signerAppExistsWithCertificates": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikalar"])},
    "choosecertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki sertifikalardan birinizi seçip imzala butonu ile belgeyi imzalayabilirsiniz."])},
    "enterpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN giriniz"])},
    "esignaturesamevalidityaswetsignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atacağınız e-imza, elle atılan ıslak imzalar ile aynı hukuki geçerliliğe sahiptir."])},
    "expirationdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son Kullanma Tarihi: "])},
    "identificationnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TC Kimlik No:"])},
    "signingdocumentwithcertificatebyenteringpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN girip imzala butonuna basarak aşağıdaki sertifika ile belgeyi imzalayabilirsiniz."])},
    "signit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzala"])},
    "youarereadytosign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalama için hazırsınız"])},
    "multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birden çok sürece işlem yapıyorsunuz. Süreç başlıkları:"])}
  },
  "signerAppIsOld": {
    "aftercompletingtheinstallationyoucanclicktherefreshbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulumu tamamladıktan sonra aşağıdaki yenile butonuna basabilirsiniz."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "buttontosetupesignatureoncomputer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza aracını bilgisayarınıza kurmak için yandaki butonu kullanabilirsiniz."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndir"])},
    "downloadesignaturetool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza Aracını İndir"])},
    "downloadformacos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["macOS için İndir"])},
    "downloadforwindows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows için İndir"])},
    "esignaturetoolisold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza Aracı'nın yeni sürümünü kurmalısınız"])},
    "presstherefreshbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki seçeneklerden birini tamamladıktan sonra yenile düğmesine basabilirsiniz"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenile"])}
  },
  "signerComponent": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "connectioncouldnotbeestablished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baglantiKurulamadı"])},
    "documentsigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge imzalandı"])},
    "equivalenttowetsignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmış olduğunuz e-imza 5070 sayılı e-imza kanunu kapsamında ıslak imza ile eşdeğerdir."])},
    "esignaturetoolcouldnotbeaccessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONAYLARIM e-İmza Aracına erişilemedi. ONAYLARIM e-İmza Aracının kurulu ve calışıyor olduğundan emin olun."])},
    "initiatingthesigningprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalama işlemi başlatılıyor."])},
    "kepmessagesent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP gönderildi."])},
    "kepsentsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP gönderme işlemi başarıyla tamamlandı."])},
    "pleasewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyin"])},
    "searchingforesignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmza aracı ve e-imza kartları aranıyor."])},
    "sendingKep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP mesajı gönderiliyor"])},
    "thesigningprocesscontinues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalama işlemi devam ediyor."])},
    "thesigningrocessiscomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalama işlemi tamamlanıyor."])},
    "wesentyouemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerçekleştirdiğiniz işlemle ilgili size bir e-posta gönderdik"])},
    "youcanclosethewindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencereyi kapatabilirsiniz"])},
    "yousignedthedocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeyi imzaladınız."])}
  },
  "signerError": {
    "anerroroccurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata oluştu"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])}
  },
  "signerWait": {
    "pleasewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyiniz"])}
  },
  "signersList": {
    "Desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra"])},
    "Empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş"])},
    "Signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılar"])},
    "posted-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderilme Tarihi"])},
    "signerstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacı/Durum"])},
    "transaction-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Tarihi"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekle"])}
  },
  "signersListLarge": {
    "approved-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarihinde onayladı"])},
    "cannot-create-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem adresi oluşturulamadı."])},
    "cannot-sent-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta gönderilemedi."])},
    "copy-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Adresini Kopyala"])},
    "e-mail-request-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta gönderme işlemi alındı."])},
    "get-transaction-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Adresini Al"])},
    "not-yet-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz gönderilmedi"])},
    "passed-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarihinde pas geçildi"])},
    "re-send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar e-Posta Gönder"])},
    "refused-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarihinde reddetti"])},
    "sent-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarihinde gönderildi"])},
    "sign-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi İmzala"])},
    "signed-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarihinde imzaladı"])},
    "signer-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeleri imzalayacak kişiler ve ve işlem durumları"])},
    "signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılar"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adım"])},
    "trade-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi İşlem Yap"])},
    "url-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem adresi panoya kopyalandı."])}
  },
  "summaryForFreePlans": {
    "after-you-sign-the-document-the-e-signed-document-will-be-sent-to-you-by-e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeyi siz imzaladıktan sonra e-imzalı doküman size e-posta olarak gönderilecektir."])},
    "give-the-process-a-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürece Bir İsim Verin"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
    "process-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç adı"])},
    "since-you-are-on-the-free-plan-the-documents-will-be-deleted-5-days-after-the-process-is-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücretsiz planda olduğunuz için süreç tamamlandıktan 3 gün sonra belgeler silinecektir."])},
    "start-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreci Başlat"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdiğiniz bilgiler imzacılara gönderilecek e-postada görüntülenecek."])},
    "the-e-signed-document-will-be-sent-to-you-by-e-mail-when-all-signatories-have-signed-the-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm imzacıları belgeleri imzaladıklarında e-imzalı doküman size e-posta olarak gönderilecektir."])},
    "the-name-you-give-to-the-process-will-appear-in-the-e-mail-sent-to-the-signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılara gönderilecek e-postada sürece verdiğiniz isim görünecek."])},
    "the-note-you-wrote-will-be-attached-to-the-e-mail-sent-to-the-signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazdığınız not imzacılara gönderilen e-posta içerisine eklenecek."])},
    "the-process-is-starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlatılıyor..."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç Bilgileri"])},
    "write-a-note-for-signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalayacaklar için Not Yazın"])},
    "write-your-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notunuzu yazın..."])},
    "your-process-will-be-started-free-of-charge-and-an-e-mail-will-be-sent-to-the-people-you-designate-to-sign-in-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreciniz ücretsiz olarak başlatılacak ve imza atmaları için belirlediğiniz kişilere sırasıyla e-posta gönderilecektir."])}
  },
  "summaryGeneral": {
    "i-will-process-later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonra işlem yapacağım"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
    "process-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlatıldı"])},
    "processnotstarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç başlatılamadı."])},
    "processnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi işlem yap"])},
    "started-with-process-but-error-occured-while-sending-email-to-contacts-error-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreçle başlatıldı ancak kişilere e-posta gönderilirken hata oluştu. Hata detayı:"])},
    "would-you-like-to-e-sign-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgelere e-imza atmak ister misiniz?"])}
  },
  "templateDesigner": {
    "5070-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu belge 5070 sayılı elektronik imza kanununa göre güvenli elektronik imza ile imzalanmıştır."])},
    "5070-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeye http://v.onaylarim.com adresinden 1234567-8901234567890123456789012345678901234567890 kodu ile erişebilirsiniz."])},
    "confirm-delete-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza alanını belgeden kaldırmak istediğinizden emin misiniz?"])},
    "confirm-delete-freetextbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin kutusunu kaldırmak istediğinizden emin misiniz?"])},
    "data-matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karekod"])},
    "delete-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza alanını sil"])},
    "delete-freetextbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin kutusunu sil"])},
    "document-can-be-downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge indirilebilir"])},
    "document-can-be-downloaded-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgenin doğruluğu teyit edilirken belge de indirilebilir"])},
    "document-cannot-be-downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge indirilemez"])},
    "document-cannot-be-downloaded-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgenin doğruluğu teyit edilebilir ancak belge indirilemez"])},
    "document-dont-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge indirilemez ve doğrulama cümlesi olmaz"])},
    "document-dont-use-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgenin doğruluğu teyit edilebilir ancak belge indirilemez. Ayrıca belge üzerinde doğrulama cümlesi olmaz."])},
    "document-validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge Doğrulama"])},
    "document-verification-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge Doğrulama Yöntemi"])},
    "drag-and-drop-signers-onto-the-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacıları belgenin üzerine sürükleyip bırakın"])},
    "ensure-all-fields-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm imzacıları dokümana yerleştirdiğinizden ve iki imza alanının üst üste gelmediğinden emin olunuz."])},
    "field-same-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aynı imzacı belgeye bir kez eklenebilir."])},
    "identifying-signature-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza Atılacak Alanları Belirleme"])},
    "qr-code-help-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge üzerine karekod eklemeniz durumunda, imzalı belge üzerinde karekod bulunur. Bu karekod akıllı telefonlarla okutulduğunda, belgenin ve üzerindeki e-imzaların doğruluğunun teyit edildiği sayfa açılır."])},
    "qr-help-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karekod eklemek mecburi değildir."])},
    "qr-help-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karekod olmayan belgeler, belgenin en altında bulunan doğrulama bilgileri ile de doğrulanabilir."])},
    "qr-help-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karekodun yerini, karekod görselini hareket ettirerek ayarlayabilirsiniz."])},
    "qr-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgenin üzerinde karekod olup olmayacağını belirleyebilirsiniz"])},
    "signature-help-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeyi imzalayacak her bir kişiye ait imza alanının, belge üzerinde bir yere yerleştirilmesi gerekmektedir."])},
    "signature-help-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalayacak kişilerin her biri farklı renkle belirtilmiştir. Her bir kişiye ait imza kutucuğunu belge üzerine sürükleyip bırakmanız gerekiyor."])},
    "signature-help-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge üzerine eklenen imza alanlarının yerlerini yine sürükle bırak ile değiştirebilirsiniz."])},
    "signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılar"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adım"])},
    "use-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KareKod olsun"])},
    "use-qr-code-false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KareKod olmasın"])},
    "verify-help-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-İmzalı belgeyi alan kişiler, belge üzerindeki karekod veya belgenin en altında bulunan doğrulama bilgileri ile bu belgenin ve üzerindeki e-imzaların doğruluğunu teyit edebilirler."])},
    "verify-help-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgenin teyit edilmesi ile beraber, teyit eden kişinin e-imzalı belgeyi indirebilmesini de istiyorsanız, 2. seçeneği, yani 'Belgenin doğruluğu teyit edilebilsin ve belge indirilebilsin''i seçmeniz gerekmektedir."])},
    "verify-help-2": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Teyit eden kişinin e-imzalı belgeyi indirebilmesini istemiyorsanız, 1. seçeneği, yani ", _linked("templateDesigner.document-cannot-be-downloaded-desc'i"), " seçmeniz gerekmektedir."])},
    "verify-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgenin doğrulama yöntemini belirleyebilirsiniz"])}
  },
  "templatesFile": {
    "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])}
  },
  "this-feature-is-not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu özellik kullanılamaz"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
  "userAccountComponent": {
    "change-name-and-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad değiştir"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı"])},
    "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlanıyor"])},
    "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı Kesilmiş"])},
    "logoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu kapat"])},
    "name-not-entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad girilmemiş"])},
    "no-connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı Yok"])},
    "reconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Bağlanıyor"])},
    "set-name-and-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad belirle"])},
    "the-connection-is-disconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı Kesiliyor"])},
    "unknown-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilinmeyen Durum"])}
  },
  "userAccountSmall": {
    "change-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad değiştir"])},
    "no-connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı Yok"])},
    "no-name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad girilmemiş"])},
    "set-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad soyad belirle"])},
    "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu kapat"])}
  },
  "userAddEdit": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "citizenship-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatandaşlık no"])},
    "delete-the-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı sil"])},
    "e-mail-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta Adresi"])},
    "is-it-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Mi"])},
    "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Soyad"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre Sıfırla"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "subtitle-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri doldurarak yeni bir kullanıcı tanımlayabilirsiniz."])},
    "subtitle-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri değiştirerek kullanıcı bilgilerini güncelleyebilirsiniz."])},
    "title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kullanıcı Tanımlama"])},
    "title-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Güncelleme"])},
    "type-the-users-citizenship-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcının TC'sini giriniz"])},
    "type-the-users-email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcının e-posta adresini yazın"])},
    "type-the-users-first-and-last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcının ad ve soyadını yazın"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "user-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı yönetimi"])}
  },
  "usersManagement": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kullanıcı ekle"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlama yapıldı"])},
    "delete-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyı sil"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı hesabı silindi."])},
    "deletion-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı hesabı silinemedi."])},
    "do-you-want-to-permanently-delete-the-selected-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili kullanıcı hesabını kalıcı olarak silmek istiyor musunuz?"])},
    "do-you-want-to-send-a-password-reset-email-to-the-selected-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili kullanıcıya şifre sıfırlama e-postası göndermek istiyor musunuz?"])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç kullanıcı tanımlanmamış"])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkisiz erişim."])},
    "no-right-error-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları görmek için gerekli yetkiniz bulunmuyor"])},
    "no-undo-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi sıfırla"])},
    "reset-password-mail-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıya şifre sıfırlama e-postası gönderildi."])},
    "reset-password-mail-sent-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıya şifre sıfırlama e-postası gönderilemedi."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumunuzdaki tüm kullanıcılar."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme yapıldı"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
    "user-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı silme"])},
    "user-password-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı şifresi sıfırlama"])}
  },
  "verifyEmailComponent": {
    "Email-could-not-be-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta doğrulanamadı. Lütfen tekrar deneyiniz."])},
    "Invalid-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz link. Lütfen e-postadaki linkin tamamını kullandığınızdan emin olun."])},
    "login-to-the-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisteme giriş yap"])},
    "verify-your-e-mail-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta adresinizi doğrulayın"])},
    "verifying-your-e-mail-address-please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta adresiniz doğrulanıyor lütfen bekleyin."])},
    "your-e-mail-address-has-been-verified-you-can-continue-your-transactions-by-logging-into-the-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Posta adresiniz doğrulandı. Sisteme giriş yaparak işlemlerinize devam edebilirsiniz."])}
  },
  "version": {
    "api-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu Versiyonu"])},
    "auth-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkilendirme Servisi"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Tabanı"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mod"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versiyon"])}
  },
  "viewFlow": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgiler"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not ve Ekler"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemler"])},
    "process-monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süreç izleme"])},
    "signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzacılar"])},
    "viewpdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokümanlar"])}
  },
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyarı"])},
  "yeni-suerec-olustur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni süreç oluştur"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])}
}